
<div class="scoreboard">
    <span>    <i class="star fas fa-star"></i> {{total}} </span>
</div>

<div class="word">
    <span>
        <b>{{randomword$ | async}}</b> 
    </span>
</div>
<div class="timer">
    <mat-progress-bar [value]="timerValue"></mat-progress-bar>
</div>

<div class="result" *ngIf="halt$ | async">

    <app-star [value]="timerValue"></app-star>

</div>

<div class="restart">
    <button (click)="resetPoints()">Nieuw spel starten</button>
    <button *ngIf="!isRandom" (click)="isRandom = true">Random</button>
    <button *ngIf="isRandom" (click)="isRandom = false">Niet random</button>
</div>
