import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WordsServiceService {

  constructor(private http: HttpClient) { 
    
  }

  public words(): Observable<any>{
    return this.http.get('./assets/words.json');
  }
}

