import { Component, OnInit, HostListener } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { WordsServiceService } from '../words-service.service';
@Component({
  selector: 'app-words',
  templateUrl: './words.component.html',
  styleUrls: ['./words.component.scss']
})
export class WordsComponent implements OnInit {

  public wordlist: any;
  public showResult = false;
  private haltSubject = new BehaviorSubject<boolean> (false);
  private randomwordSubject = new BehaviorSubject<string>('');
  public get halt$(): Observable<boolean>{
    return this.haltSubject;
  }
  public timeout: number = 2500;
  public total: number = 0;
  public timerValue: number = 0;
  public isRandom: boolean = false;
  public index: number = 0;

  public get randomword$(): Observable<string> {
    return this.randomwordSubject;
  }
  constructor(private wordsServiceService: WordsServiceService) {
    const storedTotal = localStorage.getItem('total');
    const random = localStorage.getItem('random');
    const index =  localStorage.getItem('index');
    if(!!storedTotal){
      this.total = parseInt(storedTotal);
    }

    if(!!random){
      this.isRandom = random === '1';
    }

    if(!!index){
      this.index = parseInt(index);
    }

   }

   @HostListener('window:keydown', ['$event'])
   onKeyDown($event: KeyboardEvent) {
    if(this.haltSubject.value){
      this.reset();
    } else {
      this.correct();
    }
    
   }

   public correct(): void {
    this.total += (100-this.timerValue)/10;
    this.save();
    this.haltSubject.next(true);
    this.showResult = true;
   }

 
  public tick(): void {
    if(!this.haltSubject.value && this.timerValue < 100){
      this.timerValue += 10;
    }
    setTimeout(() => this.tick(), this.timeout);
  }

  private save(): void {
    localStorage.setItem('total', this.total+'');
    localStorage.setItem('random', this.isRandom ? '1' : '0');
    localStorage.setItem('index', this.index + '');
  }

  ngOnInit(): void {
    this.wordsServiceService.words().subscribe(x => {
      this.wordlist = x;
      this.tick();
      this.reset();
    });
  }



  public resetPoints(): void {
    this.total = 0;
    this.save();
    this.reset();
  }

  public reset(): void {
    this.haltSubject.next(false);
    this.showResult = false;
    this.timerValue = 0;
    if(this.isRandom){
      this.index = Math.round(Math.random() * this.wordlist.words.length);
    }else{
      this.index++;
      if(this.index == this.wordlist.length){
        this.index = 0;
      }
    }
    
    this.randomwordSubject.next(this.wordlist.words[this.index]);
  }

}
