<div  class="star">
    <i *ngIf="value == 100" class="far fa-star"></i>
    <i *ngIf="value < 100" class="fas fa-star"></i>


    <i *ngIf="value > 90" class="far fa-star"></i>
    <i *ngIf="value <= 90" class="fas fa-star"></i>


    <i *ngIf="value > 80" class="far fa-star"></i>
    <i *ngIf="value <= 80" class="fas fa-star"></i>


    <i *ngIf="value > 60" class="far fa-star"></i>
    <i *ngIf="value <= 60" class="fas fa-star"></i>


    <i *ngIf="value > 50" class="far fa-star"></i>
    <i *ngIf="value <= 50" class="fas fa-star"></i>


    <i *ngIf="value > 40" class="far fa-star"></i>
    <i *ngIf="value <= 40" class="fas fa-star"></i>


    <i *ngIf="value > 30" class="far fa-star"></i>
    <i *ngIf="value <= 30" class="fas fa-star"></i>


    <i *ngIf="value > 20" class="far fa-star"></i>
    <i *ngIf="value <= 20" class="fas fa-star"></i>


    <i *ngIf="value > 10" class="far fa-star"></i>
    <i *ngIf="value <= 10" class="fas fa-star"></i>

    <i *ngIf="value > 0" class="far fa-star"></i>
    <i *ngIf="value <= 0" class="fas fa-star"></i>

</div>
